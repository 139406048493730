<template>
  <v-app>
    <v-main>
      <Nav />
      <RouterView />
      <Footer />
    </v-main>
  </v-app>
</template>

<script>
import Nav from "@/components/shared/Nav";
import Footer from "@/components/shared/Footer";

export default {
  name: "App",
  components: { Nav, Footer },
  data: () => ({}),
};
</script>

<style lang="scss">
</style>
