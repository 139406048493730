import { render, staticRenderFns } from "./Hero.vue?vue&type=template&id=60f0dd8e&scoped=true&"
import script from "./Hero.vue?vue&type=script&lang=js&"
export * from "./Hero.vue?vue&type=script&lang=js&"
import style0 from "./Hero.vue?vue&type=style&index=0&id=60f0dd8e&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "60f0dd8e",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VImg } from 'vuetify/lib/components/VImg';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VRow } from 'vuetify/lib/components/VGrid';
installComponents(component, {VCol,VContainer,VImg,VLayout,VProgressCircular,VRow})
