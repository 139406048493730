<template>
  <v-footer data-aos="fade-in"  data-aos-delay="100">
    <v-row justify="center" no-gutters>
      <v-btn text rounded small class="my-2" to="/"> Home </v-btn>
      <v-btn text rounded small class="my-2" to="/solutions/virtual">
        Virtual events
      </v-btn>
      <v-btn text rounded small class="my-2" to="/solutions/in-person">
        In-person events
      </v-btn>
      <v-btn text rounded small class="my-2" to="/solutions/hybrid">
        Hybrid events
      </v-btn>

      <!--<v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn text rounded small class="my-2" v-bind="attrs" v-on="on">
            About Abardo
          </v-btn>
        </template>
        <span>Coming soon!</span>
      </v-tooltip>

      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn text rounded small class="my-2" v-bind="attrs" v-on="on">
            Newsroom
          </v-btn>
        </template>
        <span>Coming soon!</span>
      </v-tooltip>

      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn text rounded small class="my-2" v-bind="attrs" v-on="on"> Blog </v-btn>
        </template>
        <span>Coming soon!</span>
      </v-tooltip>-->

      <v-btn text rounded small class="my-2" to="/pricing"> Pricing </v-btn>

      <v-btn text rounded small class="my-2" to="/support"> Support </v-btn>

      <v-col class="py-4 text-center" cols="12">
        &#169; {{ new Date().getFullYear() }} Abardo
        <v-btn text rounded small class="my-2" to="/docs/terms-of-use">
          Terms of use
        </v-btn>
        <v-btn text rounded small to="/docs/privacy-policy"> Privacy policy </v-btn>
      </v-col>
    </v-row>
  </v-footer>
</template>

<script>
export default {
  name: "Footer",
  data: () => ({}),
};
</script>

<style scoped></style>
