import Vue from 'vue'
import VueRouter from 'vue-router'

import Home from '../views/Home'

Vue.use(VueRouter)

const routes = [{
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/solutions/virtual',
    name: 'Virtual',
    component: () => import( /* webpackChunkName: "solutions" */ '../views/solutions/Virtual')
  },
  {
    path: '/solutions/in-person',
    name: 'InPerson',
    component: () => import( /* webpackChunkName: "solutions" */ '../views/solutions/InPerson')
  },
  {
    path: '/solutions/hybrid',
    name: 'Hybrid',
    component: () => import( /* webpackChunkName: "solutions" */ '../views/solutions/Hybrid')
  },
  {
    path: '/company/about',
    name: 'About',
    component: () => import( /* webpackChunkName: "about" */ '../views/company/About')
  },
  {
    path: '/company/newsroom',
    name: 'Newsroom',
    component: () => import( /* webpackChunkName: "about" */ '../views/company/Newsroom')
  },
  {
    path: '/company/blog',
    name: 'Blog',
    component: () => import( /* webpackChunkName: "about" */ '../views/company/Blog')
  },
  {
    path: '/docs/zoom',
    name: 'Zoom',
    component: () => import( /* webpackChunkName: "docs" */ '../views/docs/Zoom')
  },
  {
    path: '/docs/terms-of-use',
    name: 'Terms',
    component: () => import( /* webpackChunkName: "docs" */ '../views/docs/Terms')
  },
  {
    path: '/docs/privacy-policy',
    name: 'Privacy',
    component: () => import( /* webpackChunkName: "docs" */ '../views/docs/Privacy')
  },
  {
    path: '/contact',
    name: 'Contact',
    component: () => import( /* webpackChunkName: "contact" */ '../views/Contact')
  },
  {
    path: '/support',
    name: 'Contact',
    component: () => import( /* webpackChunkName: "contact" */ '../views/Contact')
  },
  {
    path: '/pricing',
    name: 'Pricing',
    component: () => import( /* webpackChunkName: "pricing" */ '../views/Pricing')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.afterEach(() => {
  window.scrollTo(0, 0);
});

export default router