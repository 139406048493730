<template>
  <v-container>
    <div class="d-sm-flex justify-space-around align-center">
      <div class="d-flex justify-center align-center mb-9 mb-sm-0">
        <v-img
          :src="logo1"
          max-width="200"
          data-aos="fade-in"
          
          data-aos-delay="0"
        ></v-img>
      </div>
      <div class="d-flex justify-center align-center mb-9 mb-sm-0">
        <v-img
          :src="logo2"
          max-width="200"
          data-aos="fade-in"
          
          data-aos-delay="100"
        ></v-img>
      </div>
      <div class="d-flex justify-center align-center mb-9 mb-sm-0">
        <v-img
          :src="logo3"
          max-width="140"
          data-aos="fade-in"
          
          data-aos-delay="200"
        ></v-img>
      </div>
      <div class="d-flex justify-center align-center mb-9 mb-sm-0">
        <v-img
          :src="logo4"
          max-width="80"
          data-aos="fade-in"
          
          data-aos-delay="400"
        ></v-img>
      </div>
    </div>
  </v-container>
</template>

<script>
export default {
  name: "Partners",
  data: () => ({
    logo2: require("@/assets/images/partners/microsoft-partner-gold.png"),
    logo1: require("@/assets/images/partners/microsoft-for-startups.png"),
    logo3: require("@/assets/images/partners/zoom-partners.png"),
    logo4: require("@/assets/images/partners/huawei-ict-academy.png"),
  }),
};
</script>

<style scoped></style>
