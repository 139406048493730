<template>
  <v-container>
    <div class="d-flex justify-space-between align-center mb-6">
      <div class="text-h3" data-aos="fade-in">Solutions</div>

      <div class="d-flex align-center justify-center">
        <div
          class="rounded d-flex flex-column mr-9"
          data-aos="zoom-out"
          data-aos-delay="200"
        >
          <v-icon large color="black"> mdi-video-box </v-icon>
          <span class="text-body-2 mt-1">Zoom</span>
        </div>
        <div
          class="rounded d-flex flex-column mr-9"
          data-aos="zoom-out"
          data-aos-delay="400"
        >
          <v-icon large color="black"> mdi-microsoft-teams </v-icon>
          <span class="text-body-2 mt-1">Microsoft</span>
        </div>
        <div class="rounded d-flex flex-column" data-aos="zoom-out" data-aos-delay="600">
          <v-icon large color="black"> mdi-google-maps</v-icon>
          <span class="text-body-2 mt-1">Google</span>
        </div>
      </div>
    </div>

    <div class="text-body-1 my-9" data-aos="fade-in" data-aos-delay="100">
      Empower employees to organise professional branded internal or public events.
    </div>

    <masonry
      :gutter="{ default: '3rem' }"
      :cols="{ default: 4, 1600: 3, 900: 2, 600: 1 }"
    >
      <div data-aos="zoom-in-up" data-aos-delay="200">
        <v-card
          elevation="0"
          class="card-1 rounded my-3 mb-9"
          dark
          to="/solutions/virtual"
        >
          <v-img :src="image1" :lazy-src="image2" max-height="230">
            <template v-slot:placeholder>
              <v-row class="fill-height ma-0" align="center" justify="center">
                <v-progress-circular
                  indeterminate
                  color="grey lighten-5"
                ></v-progress-circular>
              </v-row>
            </template>
          </v-img>
          <div class="d-flex align-center justify-space-between pt-4 px-4">
            <div class="text-h5">Virtual</div>
            <div>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon class="mr-2" v-bind="attrs" v-on="on"
                    >mdi-microsoft-teams</v-icon
                  >
                </template>
                <span>Microsoft Teams</span>
              </v-tooltip>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon v-bind="attrs" v-on="on">mdi-video-box</v-icon>
                </template>
                <span>Zoom</span>
              </v-tooltip>
            </div>
          </div>
          <v-card-text>
            <div>
              Create unlimited digital internal or public events for your organisation in
              minutes.
            </div>
          </v-card-text>
          <v-list-item to="solutions/virtual">
            <v-list-item-content>
              <v-list-item-title>Learn more</v-list-item-title>
            </v-list-item-content>
            <v-list-item-action>
              <v-btn icon>
                <v-icon>mdi-chevron-right</v-icon>
              </v-btn>
            </v-list-item-action>
          </v-list-item>
        </v-card>
      </div>
      <div data-aos="zoom-in-up" data-aos-delay="400">
        <v-card
          elevation="0"
          class="card-2 rounded my-3 mb-9"
          dark
          to="/solutions/in-person"
        >
          <v-img :src="image3" :lazy-src="image4" max-height="230">
            <template v-slot:placeholder>
              <v-row class="fill-height ma-0" align="center" justify="center">
                <v-progress-circular
                  indeterminate
                  color="grey lighten-5"
                ></v-progress-circular>
              </v-row>
            </template>
          </v-img>
          <div class="d-flex align-center justify-space-between pt-4 px-4">
            <div class="text-h5">In-person</div>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-icon v-bind="attrs" v-on="on">mdi-google-maps</v-icon>
              </template>
              <span>Google Maps</span>
            </v-tooltip>
          </div>
          <v-card-text>
            <div>
              Create engaging in-person digitial experience for physical events with maps.
            </div>
          </v-card-text>
          <v-list-item to="solutions/in-person">
            <v-list-item-content>
              <v-list-item-title>Learn more</v-list-item-title>
            </v-list-item-content>
            <v-list-item-action>
              <v-btn icon>
                <v-icon>mdi-chevron-right</v-icon>
              </v-btn>
            </v-list-item-action>
          </v-list-item>
        </v-card>
      </div>
      <div data-aos="zoom-in-up" data-aos-delay="600">
        <v-card
          elevation="0"
          class="card-3 rounded my-3 mb-9"
          dark
          to="/solutions/hybrid"
        >
          <v-img :src="image5" :lazy-src="image6" max-height="230">
            <template v-slot:placeholder>
              <v-row class="fill-height ma-0" align="center" justify="center">
                <v-progress-circular
                  indeterminate
                  color="grey lighten-5"
                ></v-progress-circular>
              </v-row>
            </template>
          </v-img>
          <div class="d-flex align-center justify-space-between pt-4 px-4">
            <div class="text-h5">Hybrid</div>
            <div>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon class="mr-2" v-bind="attrs" v-on="on"
                    >mdi-microsoft-teams</v-icon
                  >
                </template>
                <span>Microsoft Teams</span>
              </v-tooltip>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon class="mr-2" v-bind="attrs" v-on="on">mdi-video-box</v-icon>
                </template>
                <span>Zoom</span>
              </v-tooltip>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon v-bind="attrs" v-on="on">mdi-google-maps</v-icon>
                </template>
                <span>Google Maps</span>
              </v-tooltip>
            </div>
          </div>
          <v-card-text>
            <div>
              Create highly adaptable events for virtual and in-person global audiences.
            </div>
          </v-card-text>
          <v-list-item to="solutions/hybrid">
            <v-list-item-content>
              <v-list-item-title>Learn more</v-list-item-title>
            </v-list-item-content>
            <v-list-item-action>
              <v-btn icon>
                <v-icon>mdi-chevron-right</v-icon>
              </v-btn>
            </v-list-item-action>
          </v-list-item>
        </v-card>
      </div>
    </masonry>
  </v-container>
</template>

<script>
export default {
  name: "Solutions",
  data: () => ({
    image1: require("@/assets/images/solutions/virtual.jpg"),
    image2: require("@/assets/images/solutions/virtual-low.jpg"),
    image3: require("@/assets/images/solutions/in-person.jpg"),
    image4: require("@/assets/images/solutions/in-person-low.jpg"),
    image5: require("@/assets/images/solutions/hybrid.jpg"),
    image6: require("@/assets/images/solutions/hybrid-low.jpg"),
  }),
};
</script>

<style scoped>
.card-1 {
  background: linear-gradient(120deg, #4b79cf, #5176cf) !important;
  box-shadow: 0 1rem 2.5rem -0.9rem rgba(75, 121, 207, 0.8) !important;
}

.card-1:hover {
  box-shadow: 0 1rem 2.5rem -1.2rem rgba(75, 121, 207, 0.8) !important;
}

.card-2 {
  background: linear-gradient(120deg, #5176cf, #7d5fcf) !important;
  box-shadow: 0 1rem 2.5rem -0.9rem rgba(125, 95, 207, 0.8) !important;
}

.card-2:hover {
  box-shadow: 0 1rem 2.5rem -1.2rem rgba(125, 95, 207, 0.8) !important;
}

.card-3 {
  background: linear-gradient(120deg, #7d5fcf, #a24bcf) !important;
  box-shadow: 0 1rem 2.5rem -0.9rem rgba(162, 75, 207, 0.8) !important;
}

.card-3:hover {
  box-shadow: 0 1rem 2.5rem -1.2rem rgba(162, 75, 207, 0.8) !important;
}

.rounded {
  border-radius: 2rem !important;
}

.rounded img {
  border-radius: 2rem !important;
}

.rounded {
  transition: all 0.5s ease-in-out;
}

/* .rounded:hover {
  transform: scale(0.98);
  transition: all 0.2s ease-in-out;
} */
</style>
