<template>
  <div v-if="firstActionText || secondActionText">
    <v-btn
      data-aos="fade-in"
      data-aos-delay="100"
      
      v-if="firstActionText"
      x-large
      elevation="0"
      class="mr-sm-1 mb-1 mb-sm-0"
      :class="{ 'mb-sm-1': block }"
      :href="firstActionUrl"
      :target="firstActionTarget"
      rounded
      :dark="!dark"
      :block="$vuetify.breakpoint.xsOnly || block"
    >
      {{ firstActionText }} <v-icon>mdi-chevron-right</v-icon>
    </v-btn>
    <v-btn
      data-aos="fade-in"
      data-aos-delay="200"
      
      v-if="secondActionText"
      x-large
      outlined
      rounded
      :href="secondActionUrl"
      :target="secondActionTarget"
      :dark="dark"
      :block="$vuetify.breakpoint.xsOnly || block"
    >
      {{ secondActionText }} <v-icon>mdi-chevron-right</v-icon></v-btn
    >
  </div>
</template>

<script>
export default {
  name: "Actions",
  props: [
    "firstActionText",
    "firstActionUrl",
    "firstActionTarget",
    "secondActionText",
    "secondActionUrl",
    "secondActionTarget",
    "dark",
    "block",
  ],
  data: () => ({}),
};
</script>
