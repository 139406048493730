<template>
  <v-container class="py-6">
    <masonry :gutter="{ default: '6rem' }" :cols="{ default: 2, 600: 1 }">
      <div
        class="card gradient shadow d-flex align-center pa-6 mb-3"
        v-for="(testimonial, index) in testimonials"
        :key="testimonial.name"
        data-aos="zoom-out"
        
        :data-aos-delay="(index + 1) * 200"
      >
        <div class="avatar mr-4">
          <v-avatar :size="60" class="shadow">
            <img :src="testimonial.picture" />
          </v-avatar>
          <div class="icon-container">
            <v-avatar :size="60 / 4" class="shadow">
              <img :src="testimonial.icon" />
            </v-avatar>
          </div>
        </div>
        <div @click="testimonial.truncate = !testimonial.truncate" class="pointer">
          <div class="d-sm-flex align-center">
            <div class="text-h6">{{ testimonial.name }}</div>
            <div class="text-h6 grey--text text--darken-2 ml-sm-2">
              {{ testimonial.company }}
            </div>
          </div>
          <div
            class="text-body-1 font-weight-thin pt-1"
            :class="{ truncated: testimonial.truncate }"
          >
            {{ testimonial.strapline }}
          </div>
        </div>
        <div>
          <v-btn icon x-large @click="openUrl(testimonial.url)"
            ><v-icon>mdi-chevron-right</v-icon></v-btn
          >
        </div>
      </div>
    </masonry>
  </v-container>
</template>

<script>
export default {
  name: "Testimonials",
  data: () => ({
    testimonials: [
      {
        picture: "https://mothflower.blob.core.windows.net/assets/brit-large.jpg",
        icon: "https://mothflower.blob.core.windows.net/assets/mothflower.png",
        name: "Brit Pruiksma",
        company: "Mothflower",
        url: "https://mothflower.com",
        strapline:
          "Abardo helped me plan for the London Art Fair 2022 and became essential for my business with communicating with artists with Microsoft Teams",
        truncate: true,
      },
      {
        picture: "https://mothflower.blob.core.windows.net/assets/brit-large.jpg",
        icon: "https://mothflower.blob.core.windows.net/assets/mothflower.png",
        name: "Another Person",
        company: "Abardo",
        url: "https://mothflower.com",
        strapline:
          "Abardo helped me plan for the London Art Fair 2022 and became essential for my business with communicating with artists with Microsoft Teams",
        truncate: true,
      },
    ],
  }),
  methods: {
    openUrl(url) {
      window.open(url, "_blank");
    },
  },
};
</script>

<style scoped>
.avatar {
  display: inline-block;
  position: relative;
}

img {
  position: absolute;
}

.icon-container {
  position: absolute;
  bottom: 0rem;
  right: 0rem;
}

.truncated {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
}
</style>
