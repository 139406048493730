<template>
  <div>
    <v-app-bar flat class="bar">
      <v-app-bar-nav-icon
        @click.stop="drawer = !drawer"
        v-if="$vuetify.breakpoint.xsOnly"
        class="mr-2"
      ></v-app-bar-nav-icon>

      <router-link to="/">
        <v-img :src="logoIcon" max-width="30" max-height="30" class="ml-1 mr-6" />
      </router-link>

      <v-toolbar-title v-if="!$vuetify.breakpoint.xsOnly">Abardo</v-toolbar-title>

      <v-spacer></v-spacer>

      <v-btn text rounded to="/" v-if="!$vuetify.breakpoint.xsOnly"> Home </v-btn>

      <v-menu
        open-on-hover
        bottom
        offset-y
        transition="scale-transition"
        v-if="!$vuetify.breakpoint.xsOnly"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn text rounded v-bind="attrs" v-on="on"> Solutions </v-btn>
        </template>

        <v-card>
          <v-card-title>Event solutions</v-card-title>

          <v-list-item two-line to="/solutions/virtual">
            <v-list-item-icon>
              <v-icon>mdi-video-box</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Virtual events</v-list-item-title>
              <v-list-item-subtitle class="text-wrap"
                >Create digital internal or public events for your
                organisation.</v-list-item-subtitle
              >
            </v-list-item-content>
            <v-list-item-icon>
              <v-icon>mdi-chevron-right</v-icon>
            </v-list-item-icon>
          </v-list-item>

          <v-list-item two-line to="/solutions/in-person">
            <v-list-item-icon>
              <v-icon>mdi-map-marker</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>In-person events</v-list-item-title>
              <v-list-item-subtitle class="text-wrap"
                >Create a digital experience for physical events.</v-list-item-subtitle
              >
            </v-list-item-content>
            <v-list-item-icon>
              <v-icon>mdi-chevron-right</v-icon>
            </v-list-item-icon>
          </v-list-item>

          <v-list-item two-line to="/solutions/hybrid">
            <v-list-item-icon>
              <v-icon>mdi-badge-account</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Hybrid events</v-list-item-title>
              <v-list-item-subtitle class="text-wrap"
                >Create adaptable events for virtual and in-person global
                audiences.</v-list-item-subtitle
              >
            </v-list-item-content>
            <v-list-item-icon>
              <v-icon>mdi-chevron-right</v-icon>
            </v-list-item-icon>
          </v-list-item>
        </v-card>
      </v-menu>

      <!--

      <v-menu
        open-on-hover
        bottom
        offset-y
        transition="scale-transition"
        v-if="!$vuetify.breakpoint.xsOnly"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn text rounded v-bind="attrs" v-on="on"> Company </v-btn>
        </template>

        <v-card>
          <v-card-title>Company</v-card-title>

          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-list-item v-bind="attrs" v-on="on">
                <v-list-item-content>
                  <v-list-item-title>About Abardo</v-list-item-title>
                </v-list-item-content>
                <v-list-item-icon>
                  <v-icon>mdi-chevron-right</v-icon>
                </v-list-item-icon>
              </v-list-item>
            </template>
            <span>Coming soon!</span>
          </v-tooltip>

          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-list-item v-bind="attrs" v-on="on">
                <v-list-item-content>
                  <v-list-item-title>Newsroom</v-list-item-title>
                </v-list-item-content>
                <v-list-item-icon>
                  <v-icon>mdi-chevron-right</v-icon>
                </v-list-item-icon>
              </v-list-item>
            </template>
            <span>Coming soon!</span>
          </v-tooltip>

          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-list-item v-bind="attrs" v-on="on">
                <v-list-item-content>
                  <v-list-item-title>Blog</v-list-item-title>
                </v-list-item-content>
                <v-list-item-icon>
                  <v-icon>mdi-chevron-right</v-icon>
                </v-list-item-icon>
              </v-list-item>
            </template>
            <span>Coming soon!</span>
          </v-tooltip>


          <v-list-item v-bind="attrs" v-on="on">
            <v-list-item-content>
              <v-list-item-title>Support</v-list-item-title>
            </v-list-item-content>
            <v-list-item-icon>
              <v-icon>mdi-chevron-right</v-icon>
            </v-list-item-icon>
          </v-list-item>
        </v-card>
      </v-menu>

                -->

      <v-btn v-if="!$vuetify.breakpoint.xsOnly" text rounded to="/pricing">
        Pricing
      </v-btn>

      <v-btn v-if="!$vuetify.breakpoint.xsOnly" text rounded to="/contact">
        Contact
      </v-btn>

      <v-spacer></v-spacer>

      <v-btn text rounded href="https://app.abardo.com/sign-in" target="_blank">
        <span class="mr-1">Sign in</span>
        <v-icon>mdi-chevron-right</v-icon>
      </v-btn>
    </v-app-bar>

    <v-navigation-drawer v-model="drawer" absolute>
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title class="text-h6"> Abardo </v-list-item-title>
          <v-list-item-subtitle> All-in-one event platform. </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>

      <v-divider></v-divider>

      <v-list nav dense>
        <v-list-item-group>
          <v-list-item to="/">
            <v-list-item-content>
              <v-list-item-title>Home</v-list-item-title>
            </v-list-item-content>
            <v-list-item-icon>
              <v-icon>mdi-chevron-right</v-icon>
            </v-list-item-icon>
          </v-list-item>

          <v-list-item to="/solutions/virtual">
            <v-list-item-content>
              <v-list-item-title>Virtual events</v-list-item-title>
            </v-list-item-content>
            <v-list-item-icon>
              <v-icon>mdi-chevron-right</v-icon>
            </v-list-item-icon>
          </v-list-item>

          <v-list-item to="/solutions/in-person">
            <v-list-item-content>
              <v-list-item-title>In-person events</v-list-item-title>
            </v-list-item-content>
            <v-list-item-icon>
              <v-icon>mdi-chevron-right</v-icon>
            </v-list-item-icon>
          </v-list-item>

          <v-list-item to="/solutions/hybrid">
            <v-list-item-content>
              <v-list-item-title>Hybrid events</v-list-item-title>
            </v-list-item-content>
            <v-list-item-icon>
              <v-icon>mdi-chevron-right</v-icon>
            </v-list-item-icon>
          </v-list-item>

          <!--<v-list-item to="/company/about" disabled>
            <v-list-item-content>
              <v-list-item-title>About Abardo</v-list-item-title>
            </v-list-item-content>
            <v-list-item-icon>
              <v-icon>mdi-chevron-right</v-icon>
            </v-list-item-icon>
          </v-list-item>

          <v-list-item to="/company/newsroom" disabled>
            <v-list-item-content>
              <v-list-item-title>Newsroom</v-list-item-title>
            </v-list-item-content>
            <v-list-item-icon>
              <v-icon>mdi-chevron-right</v-icon>
            </v-list-item-icon>
          </v-list-item>

          <v-list-item to="/company/blog" disabled>
            <v-list-item-content>
              <v-list-item-title>Blog</v-list-item-title>
            </v-list-item-content>
            <v-list-item-icon>
              <v-icon>mdi-chevron-right</v-icon>
            </v-list-item-icon>
          </v-list-item>

          <v-list-item to="/company/partners" disabled>
            <v-list-item-content>
              <v-list-item-title>Partners</v-list-item-title>
            </v-list-item-content>
            <v-list-item-icon>
              <v-icon>mdi-chevron-right</v-icon>
            </v-list-item-icon>
          </v-list-item>-->

          <v-list-item to="/pricing">
            <v-list-item-content>
              <v-list-item-title>Pricing</v-list-item-title>
            </v-list-item-content>
            <v-list-item-icon>
              <v-icon>mdi-chevron-right</v-icon>
            </v-list-item-icon>
          </v-list-item>

          <v-list-item to="/contact">
            <v-list-item-content>
              <v-list-item-title>Contact</v-list-item-title>
            </v-list-item-content>
            <v-list-item-icon>
              <v-icon>mdi-chevron-right</v-icon>
            </v-list-item-icon>
          </v-list-item>

          <!-- <v-list-item href="https://calendly.com/abardo/demo" target="_blank">
            <v-list-item-content>
              <v-list-item-title>Book a demo</v-list-item-title>
            </v-list-item-content>
            <v-list-item-icon>
              <v-icon>mdi-chevron-right</v-icon>
            </v-list-item-icon>
          </v-list-item> -->
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>
  </div>
</template>

<script>
import Icon from "@/assets/images/abardo-circle.svg";

export default {
  name: "TopNav",
  data: () => ({
    logoIcon: Icon,
    drawer: false,
  }),
};
</script>

<style scoped></style>
