import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import router from './router'

import AOS from "aos";
import "aos/dist/aos.css";

import VueMasonry from 'vue-masonry-css'
Vue.use(VueMasonry);

import Meta from "vue-meta";
Vue.use(Meta, {
  refreshOnceOnNavigation: true,
});

import "@/assets/scss/card.scss";
import "@/assets/scss/animations.scss";
import "@/assets/scss/theme.scss";

Vue.config.productionTip = false

new Vue({
  vuetify,
  router,
  created() {
    AOS.init({
      duration: 1000
    });
  },
  render: h => h(App)
}).$mount('#app')