<template>
  <div>
    <Hero
      title="Abardo"
      subTitle="All-in-one event platform"
      description="Create immersive virtual, in-person, and hybrid digital event experiences."
      firstActionText="Try free now"
      firstActionUrl="https://app.abardo.com/sign-in"
      firstActionTarget="_blank"
      image="hero/home.png"
      :palette="['#4b79cf', '#5176cf', '#7d5fcf', '#9850cf', '#a24bcf']"
      :angle="120"
      :dark="true"
      :gradientAnimation="true"
      :transparency="true"
      :fillHeight="true"
      animationName="fade-up"
      :virtual="true"
      :inPerson="true"
    />

    <Solutions class="my-9" />

    <Testimonials class="my-9" v-if="false" />

    <Partners class="my-9" />
  </div>
</template>

<script>
import Solutions from "@/components/home/Solutions";
import Hero from "@/components/shared/Hero";
import Partners from "@/components/shared/Partners";
import Testimonials from "@/components/shared/Testimonials";

export default {
  components: { Hero, Solutions, Partners, Testimonials },
  data: () => ({}),
  metaInfo: () => ({
    title: "Abardo - All-in-one event platform",
  }),
};
</script>
