<template>
  <v-container
    fluid
    :fill-height="fillHeight && !$vuetify.breakpoint.xsOnly"
    class="hero py-12 py-sm-0"
    :class="{ animation: gradientAnimation, fillHeight: fillHeight }"
    :style="{ background: background }"
  >
    <v-row no-gutters>
      <v-col cols="12" sm="8" class="px-12 py-12">
        <v-layout
          justify-center
          column
          :data-aos="animationName"
          
        >
          <div
            class="text-h2 text-sm-h1 font-weight-black mb-3"
            :class="{ 'white--text': dark, 'transparent-2': transparency }"
            v-if="title"
          >
            {{ title }}
          </div>
          <div
            class="text-h2 text-sm-h1 font-weight-black mb-6"
            :class="{ 'white--text': dark, 'transparent-1': transparency }"
            v-if="subTitle"
          >
            {{ subTitle }}
          </div>

          <div
            class="text-h6 text-sm-h4"
            :class="{ 'white--text': dark, 'transparent-2': transparency }"
            v-if="description"
          >
            {{ description }}
          </div>

          <div class="my-9" v-if="firstActionText || secondActionText">
            <Actions
              :firstActionText="firstActionText"
              :firstActionUrl="firstActionUrl"
              :firstActionTarget="firstActionTarget"
              :secondActionText="secondActionText"
              :secondActionUrl="secondActionUrl"
              :secondActionTarget="secondActionTarget"
              :dark="dark"
            />
          </div>
        </v-layout>
      </v-col>
      <v-col cols="12" sm="4">
        <div class="py-sm-12 px-12" v-if="image">
          <v-img
            :src="imageSrc"
            :lazy-src="imageSrcLow"
            width="400"
            data-aos="zoom-out"
            
          >
            <template v-slot:placeholder>
              <v-row class="fill-height ma-0" align="center" justify="center">
                <v-progress-circular
                  indeterminate
                  color="grey lighten-5"
                ></v-progress-circular>
              </v-row> </template
          ></v-img>
        </div> </v-col
    ></v-row>
  </v-container>
</template>

<script>
import Actions from "@/components/shared/Actions";

export default {
  name: "Hero",
  props: [
    "title",
    "subTitle",
    "description",
    "firstActionText",
    "firstActionUrl",
    "firstActionTarget",
    "secondActionText",
    "secondActionUrl",
    "secondActionTarget",
    "image",
    "imageLow",
    "palette",
    "dark",
    "angle",
    "transparency",
    "gradientAnimation",
    "fillHeight",
    "animationName",
    "virtual",
    "inPerson",
  ],
  components: { Actions },
  computed: {
    imageSrc() {
      if (this.image) return require("@/assets/images/" + this.image);
      else return null;
    },
    imageSrcLow() {
      if (this.imageLow) return require("@/assets/images/" + this.imageLow);
      else return null;
    },
    background() {
      if (this.palette && this.palette.length > 0) {
        let color = "linear-gradient(" + this.angle + "deg";
        this.palette.forEach((paletteColor) => {
          color += "," + paletteColor;
        });
        color += ")";
        return color;
      } else {
        return null;
      }
    },
  },
};
</script>

<style scoped>
.hero {
  width: 100%;
}

.hero.fill-height {
  height: 100vh;
  max-height: 100vh;
}

.hero.animation {
  animation: Gradient 30s ease infinite !important;
  background-size: 200% 200% !important;
}

.transparent-1 {
  opacity: 0.7;
}

.transparent-2 {
  opacity: 0.9;
}
</style>
